export enum Persona {
  None = 0,
  Assembler_and_Arranger = 1,
  Creator_of_Content = 2,
  Consumer_of_Content = 3,
  Internal_Admin = 4
}

export function formatPersona(persona: Persona): string {
  return Persona[persona].replace(/_/g, ' ');
}